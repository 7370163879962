import styled from 'styled-components'

import { mq, sizes } from '../../theme'

export const Container = styled.div`
  width: 100%;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media ${mq(sizes.desktop)} {
    padding: 0;
    flex-direction: row;
    column-gap: 2.8rem;
  }
`

export const Main = styled.div`
  gap: 3rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Side = styled.div`
  gap: 3rem;
  display: flex;
  flex-direction: column;
  @media ${mq(sizes.desktop)} {
    width: min(calc(100% / 3), 40.8rem);
    flex-shrink: 0;
  }
`
