import React, { FC } from 'react'

import * as SC from './styled'

export type TwoColumnsLayoutProps = {
  className?: string
  mainContent?: React.ReactNode
  sideContent?: React.ReactNode
}

const MainLayout: FC<TwoColumnsLayoutProps> = ({
  className,
  mainContent,
  sideContent,
}) => {
  return (
    <SC.Container className={className}>
      <SC.Main>{mainContent}</SC.Main>
      {sideContent && <SC.Side>{sideContent}</SC.Side>}
    </SC.Container>
  )
}

export default MainLayout
